import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Id', 'calllogid', true, 'desc', 'left'),
  new TableColumn('Timestamp', 'calltimestamp', true, 'desc', 'left'),
  new TableColumn('Username', 'username', true, 'desc', 'left'),
  new TableColumn('Tier', 'tier', false, 'asc', 'left'),
  new TableColumn('Price', 'price', false, 'asc', 'right'),
  new TableColumn('Rego', 'rego', true, 'asc', 'left'),
  new TableColumn('State', 'statecode', true, 'asc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
