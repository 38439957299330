<template>
  <div>
    <app-detail-header :show-toggle="false"
      :show-new="false"
      :show-print="false"
      :show-delete="false"
      :show-save="false"
      :show-cancel="true"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <router-view v-if="callLog"
      :value="callLog.vehicleData"
      :options="options" />
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import VehicleLookupService from './VehicleLookupService'
import VehicleLookupRoutes from './route-types'
import { AppHeaderButtonTypes } from '@/enums'

export default {
  name: 'VehicleLookupView',
  components: {
    AppDetailHeader
  },
  props: {},
  data: () => {
    return {
      callLogId: 0,
      callLog: null,
      options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        line: true,
        matchBrackets: true,
        showCursorWhenSelecting: true,
        lineSeparator: '\n',
        lineWrapping: true,
        mode: { name: 'javascript', json: true },
        theme: 'default'
      }
    }
  },
  created() {
    if (this.$userInfo.codemirrorTheme) {
      this.options.theme = this.$userInfo.codemirrorTheme
    }
    this.callLogId = this.$route.params.callLogId
    this.getCallLog()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    cancel() {
      // console.log('back')
      this.$router.push(VehicleLookupRoutes.VehicleLookupListView.path)
    },
    async getCallLog() {
      this.callLog = await VehicleLookupService.getCallLog(this.callLogId)
      // test display JSON data
      // console.log(this.callLog)
      this.callLog.vehicleData = JSON.stringify(this.callLog.vehicles, null, '\t')
    }
  }
}
</script>