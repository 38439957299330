<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="false"
      :show-new="false"
      :show-print="false"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div v-if="!subscription && !isTableLoading"
      class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <article class="message is-danger">
          <div class="message-header">
            <p>No Subscription</p>
          </div>
          <div class="message-body">
            There is no active subscription. Please contact FlexiQuote support.
          </div>
        </article>
      </article>
    </div>
    <div v-else
      class="columns is-gapless">
      <div class="column is-narrow">
        <vehicle-lookup-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div v-if="subscription"
              class="field">
              <div class="field-body">
                <div class="field">
                  <label class="label">Subscription Date</label>
                  <span class="has-text-weight-bold has-text-primary">{{ $filters.formatDateLocale(subscription.purchaseDate, $userInfo.locale) }}</span>
                </div>
                <div class="field">
                  <label class="label">Balance</label>
                  <span class="has-text-weight-bold has-text-success">{{ subscription.balance | formatCurrency($userInfo.locale) }}</span>
                </div>
                <div class="field">
                  <label class="label">Usage</label>
                  <span class="has-text-weight-bold has-text-success">{{ subscription.usage | formatCurrency($userInfo.locale) }}</span>
                </div>
              </div>
            </div>
            <label class="label">Usage History</label>
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(c, index) in calllogs"
                :key="c.calllogid"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>{{ c.callLogId }}</td>
                <td>{{ $filters.formatDateTimeLocale(c.callTimestamp, $userInfo.locale) }}</td>
                <td>{{ c.username }}</td>
                <td>{{ c.tierName }}</td>
                <td class="has-text-right">{{ c.price | formatCurrency($userInfo.locale) }}</td>
                <td>{{ c.rego }}</td>
                <td>{{ c.stateCode }}</td>
                <td class="has-text-centered is-content-width">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="view(c, $event)"
                    data-tooltip="Click to view">
                    <span class="icon is-medium">
                      <i class="mdi mdi-code-json mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import BulmaTable from '@/components/BulmaTable'
import VehicleLookupListSideMenu from './VehicleLookupListSideMenu'
import VehicleLookupService from './VehicleLookupService'
import { Columns } from './columns'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'VehicleLookupListView',
  components: {
    AppDetailHeader,
    BulmaTable,
    VehicleLookupListSideMenu
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {},
  data: () => {
    return {
      subscription: null,
      calllogs: null,
      selectedRow: null,
      totalRows: 0,
      filter: {
        subscriptionId: 0,
        query: '',
        sortBy: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 15
      }
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  async created() {
    await this.getActiveSubscription()
    this.getSubscriptionCallLogSummaries()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked() {},
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getActiveSubscription()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getActiveSubscription()
    },
    filterList(filter) {
      // console.log(filter)
      this.filter.query = filter.query
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getActiveSubscription()
    },
    resetList() {
      this.filter.query = ''
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getActiveSubscription()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
        // console.log('this.filter', this.filter)
      }
    },
    generateUrl(id) {
      return `vehiclelookup/${id}`
    },
    view(calllog, event) {
      // console.log(calllog)
      this.$router.push({
        name: 'VehicleLookupDetail',
        params: { callLogId: calllog.callLogId }
        // meta: { value: calllog }
      })
    },
    async getActiveSubscription() {
      this.isTableLoading = true
      this.subscription = await VehicleLookupService.getActiveSubscription()
      if (this.subscription) {
        this.filter.subscriptionId = this.subscription.subscriptionId
        this.getSubscriptionCallLogSummaries()
      } else {
        this.isTableLoading = false
      }
    },
    async getSubscriptionCallLogSummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.calllogs = await VehicleLookupService.getSubscriptionCallLogSummaries(this.filter)
      this.totalRows = this.calllogs.length > 0 ? this.calllogs[0].totalRows : 0
      this.isTableLoading = false
    }
  }
}
</script>