import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'

export default {

  serialiseSubscriptionFilter(filter) {
    const entityProto = require('../../assets/proto/vehiclelookup/SubscriptionFilter.proto')
    const filterMessage = entityProto.SubscriptionFilter.SubscriptionFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getSubscriptionSummaries(filter) {
    const serialised = this.serialiseSubscriptionFilter(filter)
    const url = `/vehiclelookup/subscription/summaries?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getActiveSubscription() {
    const url = '/vehiclelookup/subscription/active'
    const result = await FqApi.get(url)
    return result.data
  },
  async getCallLog(callLogId) {
    const url = `/vehiclelookup/calllog/${callLogId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getSubscriptionCallLogs(subscriptionid) {
    const url = `/vehiclelookup/subscription/calllog/${subscriptionid}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseCallLogFilter(filter) {
    const entityProto = require('../../assets/proto/vehiclelookup/CallLogFilter.proto')
    const filterMessage = entityProto.CallLogFilter.CallLogFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getSubscriptionCallLogSummaries(filter) {
    const serialised = this.serialiseCallLogFilter(filter)
    const url = `/vehiclelookup/subscription/calllog/summaries?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  }
}